import { Skeleton } from "@nextui-org/react";
import styles from "./categoriesSectionSkeleton.module.css";


const CategoriesSectionSkeleton = () => {
    return (
        <Skeleton className={styles.skeleton}></Skeleton>
    );
};
export default CategoriesSectionSkeleton;
