import { Button } from '@nextui-org/react'
import Link from 'next/link'
import styles from './telegramBtn.module.css'


const TelegramBtn = () => {
    return (
        <div className={styles.container}>
            <div className={styles.containerInner}>
                <Button
                    variant='solid'
                    size="lg"
                    color="default"
                    as={Link}
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://t.me/+Wj_pNVuCO9YzZDk9'
                    className={styles.button}
                >
                    <span>Join <span className={styles.buttonSpan1}>our</span> Telegram <span className={styles.buttonSpan2}>Channel</span></span>
                </Button>
            </div>
        </div>
    )
}

export default TelegramBtn