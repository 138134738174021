import { Chip, Skeleton } from '@nextui-org/react'
import styles from './ageWarningSkeleton.module.css'


const AgeWarningSkeleton = () => {
    return (
        <Skeleton className={styles.skeleton}>
            <Chip variant="flat" color="warning" radius='md' classNames={{
                base: styles.base,
                content: styles.content
            }}>This content is intended for mature audiences. Viewer discretion is advised.</Chip>
        </Skeleton>
    )
}

export default AgeWarningSkeleton