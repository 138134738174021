"use client";
import { Chip } from "@nextui-org/react";
import { useEffect, useState } from "react";
import styles from "./ageWarning.module.css"; // Import SCSS module
import AgeWarningSkeleton from "@/components/ageWarning/ageWarningSkeleton";

const AgeWarning = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    return !loading ? (
        <section className={styles.container}>
            <Chip
                variant="flat"
                color="warning"
                radius="md"
                classNames={{
                    base: styles.chipBase,
                    content: styles.chipContent,
                }}
            >
                This content is intended for mature audiences. Viewer discretion is advised.
            </Chip>
        </section>
    ) : (
        <AgeWarningSkeleton />
    );
};

export default AgeWarning;
