import { Card, CardBody, Chip, Skeleton } from "@nextui-org/react"
import styles from "./categoryCardSkeleton.module.css"

const CategoryCardSkeleton = () => {
    return (
        <Skeleton>
            <Card className={styles.card}>
                <CardBody>
                    <Chip
                        size="lg"
                        radius="md"
                        classNames={{
                            base: styles.chipBase,
                            content: styles.chipContent,
                        }}
                    >
                        XXXXXXXXXXX
                    </Chip>
                </CardBody>
            </Card>
        </Skeleton>
    )
}

export default CategoryCardSkeleton