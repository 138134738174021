"use client"
import { lazy, Suspense, useEffect, useState } from 'react';
import { query, collection, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { Button, Chip } from '@nextui-org/react';
import { useFirebase } from '@/app/context/Firebase';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay } from 'swiper/modules';
import { useRouter } from 'next/navigation';
import ReelSwiperSectionSkeleton from '@/components/reelSwiperSection/reelSwiperSectionSkeleton';
import ReelSwiperCardSkeleton from '@/components/reelSwiperCard/reelSwiperCardSkeleton';
const ReelSwiperCard = lazy(() => import('@/components/reelSwiperCard/reelSwiperCard'));
import styles from './reelSwiperSection.module.css'; // Import SCSS module



const ReelSwiperSection = ({ whereConditions = [], orderByConditions = [], limitCount = 0, sectionHeading = "", viewBtnUrl = "" }) => {
    const [reels, setReels] = useState([]);
    const [loading, setLoading] = useState(true);
    const { firebaseDB: db } = useFirebase();
    const router = useRouter();


    const fetchReels = async () => {
        let q = collection(db, 'reels');

        // Apply where conditions if provided
        if (whereConditions.length > 0) {
            whereConditions.forEach(condition => {
                q = query(q, where(...condition));
            });
        }

        // Apply orderBy conditions if provided
        if (orderByConditions.length > 0) {
            orderByConditions.forEach(condition => {
                q = query(q, orderBy(...condition));
            });
        }

        // Apply limit if provided
        if (limitCount > 0) {
            q = query(q, limit(limitCount));
        }

        // Fetch data
        const querySnapshot = await getDocs(q);
        const fetchedReels = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setReels(fetchedReels);
        setLoading(false);
    };

    useEffect(() => {
        fetchReels();
    }, [orderByConditions, limitCount, sectionHeading, viewBtnUrl, db]);


    return (
        !loading ? (
            reels?.length ?
                <section className={styles.section}>
                    <div className={styles.sectionInner}>
                        <span>{sectionHeading}</span>
                        <Button
                            variant='bordered'
                            size='sm'
                            color='default'
                            className='ml-2'
                            as={Link}
                            href={viewBtnUrl ? `${viewBtnUrl}` : `/category/${reels?.[0]?.category?.slug}`}
                        >View All</Button>
                    </div>
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={10}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        grabCursor={true}
                        loop={true}
                        modules={[Autoplay]}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                            1280: {
                                slidesPerView: 5,
                                spaceBetween: 30,
                            },
                            1536: {
                                slidesPerView: 5,
                                spaceBetween: 30,
                            },
                        }}
                        className={`${styles.swiper}[&_.swiper-pagination-bullet]:bg-white [&_.swiper-pagination]:!left-0 [&_.swiper-pagination]:!transform-none [&_.swiper-wrapper]:flex [&_.swiper-wrapper]:items-stretch`}
                    >
                        {reels && reels?.map(reel => (
                            <SwiperSlide
                                key={reel?.id}
                                className={styles.swiperSlide}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    window.open(`${reel?.linkUrl}`)
                                }}
                            >
                                <div className={styles.chipContainer}>
                                    <Chip
                                        color='default'
                                        variant='solid'
                                        classNames={{
                                            base: styles.chipBase,
                                            // dot: "dot-classes",
                                            content: styles.chipContent,
                                            // avatar: "avatar-classes",
                                            // closeButton: "close-button-classes",
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            router.push(`/category/${reel?.category?.slug}`)
                                        }}
                                    >
                                        {reel?.category?.name}
                                    </Chip>
                                </div>
                                <Suspense fallback={<ReelSwiperCardSkeleton />}>
                                    <ReelSwiperCard reel={reel} />
                                </Suspense>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </section>
                :
                <></>
        )
            :
            <ReelSwiperSectionSkeleton />
    );
};

export default ReelSwiperSection;
