import { Skeleton } from "@nextui-org/react";
import styles from "./heroSectionSkeleton.module.css";


const HeroSectionSkeleton = () => {
    return (
        <Skeleton className={styles.skeleton}></Skeleton>
    );
}

export default HeroSectionSkeleton;
