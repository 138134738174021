"use client";
import { lazy, Suspense, useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useFirebase } from "@/app/context/Firebase";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import { useRouter } from "next/navigation";
import Image from "next/image";
import CategoriesSectionSkeleton from "@/components/categoriesSection/categoriesSectionSkeleton";
import styles from "./categoriesSection.module.css"; // Import SCSS module
const CategoryCard = lazy(() => import("@/components/categoryCard/categoryCard"));
import CategoryCardSkeleton from "../categoryCard/categoryCardSkeleton";

const CategoriesSection = () => {
    const [loading, setLoading] = useState(true);
    const { firebaseDB: db } = useFirebase();
    const [categories, setCategories] = useState([]);
    const router = useRouter();

    useEffect(() => {
        const fetchCategories = async () => {
            const querySnapshot = await getDocs(collection(db, "categories"));
            const categoriesData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCategories(categoriesData);
            setLoading(false);
        };

        fetchCategories();
    }, [db]);

    return !loading ? (
        <section className={styles.container}>
            <Image
                fill 
                quality={80}
                loading="lazy"
                className={styles.image}
                src={
                    "https://images.unsplash.com/photo-1527199372136-dff50c10ea34?q=80&w=1080&h=250&auto=format&fit=crop&fm=webp"
                }
                alt="Categories"
            />
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={10}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                grabCursor={true}
                loop={true}
                freeMode={true}
                modules={[Autoplay, FreeMode]}
                className={styles.swiper}
            >
                {categories &&
                    categories.map((category) => (
                        <SwiperSlide
                            key={category?.id}
                            onClick={() => router.push(`/category/${category?.slug}`)}
                            className="!w-auto"
                        >
                            <Suspense fallback={<CategoryCardSkeleton />}>
                                <CategoryCard category={category} />
                            </Suspense>
                        </SwiperSlide>
                    ))}
            </Swiper>
        </section>
    ) : (
        <CategoriesSectionSkeleton />
    );
};

export default CategoriesSection;
