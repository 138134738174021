import { Skeleton } from "@nextui-org/react";
import styles from "./reelSwiperSectionSkeleton.module.css";

const ReelSwiperSectionSkeleton = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Skeleton className={styles.titleSkeleton}>Trending Clips</Skeleton>
                <Skeleton className={styles.viewAllSkeleton}>View All</Skeleton>
            </div>
            <div className={styles.gridContainer}>
                <div className={styles.skeletonWrapper}>
                    <div className={styles.overlay}>
                        <div className={styles.overlayBackground}></div>
                    </div>
                    <div className={styles.skeletonCard}>
                        <Skeleton className={styles.imageSkeleton}></Skeleton>
                        <Skeleton className={styles.textSkeleton}>XXXXXXXXXXXXXX</Skeleton>
                        <div className={styles.flexSkeletonContainer}>
                            <Skeleton className={styles.subTextSkeleton}>XXXXXX</Skeleton>
                            <Skeleton className={`${styles.subTextSkeleton} ${styles.subTextSkeletonMargin}`}>XXXXXXXX</Skeleton>
                        </div>
                    </div>
                </div>
                <div className={styles.skeletonWrapper}>
                    <div className={styles.overlay}>
                        <div className={styles.overlayBackground}></div>
                    </div>
                    <div className={styles.skeletonCard}>
                        <Skeleton className={styles.imageSkeleton}></Skeleton>
                        <Skeleton className={styles.textSkeleton}>XXXXXXXXXXXXXX</Skeleton>
                        <div className={styles.flexSkeletonContainer}>
                            <Skeleton className={styles.subTextSkeleton}>XXXXXX</Skeleton>
                            <Skeleton className={`${styles.subTextSkeleton} ${styles.subTextSkeletonMargin}`}>XXXXXXXX</Skeleton>
                        </div>
                    </div>
                </div>
                <div className={`${styles.skeletonWrapper} ${styles.skeletonHiddenSmall}`}>
                    <div className={styles.overlay}>
                        <div className={styles.overlayBackground}></div>
                    </div>
                    <div className={styles.skeletonCard}>
                        <Skeleton className={styles.imageSkeleton}></Skeleton>
                        <Skeleton className={styles.textSkeleton}>XXXXXXXXXXXXXX</Skeleton>
                        <div className={styles.flexSkeletonContainer}>
                            <Skeleton className={styles.subTextSkeleton}>XXXXXX</Skeleton>
                            <Skeleton className={`${styles.subTextSkeleton} ${styles.subTextSkeletonMargin}`}>XXXXXXXX</Skeleton>
                        </div>
                    </div>
                </div>
                <div className={`${styles.skeletonWrapper} ${styles.skeletonHiddenMedium}`}>
                    <div className={styles.overlay}>
                        <div className={styles.overlayBackground}></div>
                    </div>
                    <div className={styles.skeletonCard}>
                        <Skeleton className={styles.imageSkeleton}></Skeleton>
                        <Skeleton className={styles.textSkeleton}>XXXXXXXXXXXXXX</Skeleton>
                        <div className={styles.flexSkeletonContainer}>
                            <Skeleton className={styles.subTextSkeleton}>XXXXXX</Skeleton>
                            <Skeleton className={`${styles.subTextSkeleton} ${styles.subTextSkeletonMargin}`}>XXXXXXXX</Skeleton>
                        </div>
                    </div>
                </div>
                <div className={`${styles.skeletonWrapper} ${styles.skeletonHiddenLarge}`}>
                    <div className={styles.overlay}>
                        <div className={styles.overlayBackground}></div>
                    </div>
                    <div className={styles.skeletonCard}>
                        <Skeleton className={styles.imageSkeleton}></Skeleton>
                        <Skeleton className={styles.textSkeleton}>XXXXXXXXXXXXXX</Skeleton>
                        <div className={styles.flexSkeletonContainer}>
                            <Skeleton className={styles.subTextSkeleton}>XXXXXX</Skeleton>
                            <Skeleton className={`${styles.subTextSkeleton} ${styles.subTextSkeletonMargin}`}>XXXXXXXX</Skeleton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReelSwiperSectionSkeleton;
