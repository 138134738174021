"use client"
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './heroSection.module.css';


// import required modules
import { Autoplay, Keyboard, EffectFade, Navigation, Pagination } from 'swiper/modules';
import HeroSectionSkeleton from './heroSectionSkeleton';
import TelegramBtn from '../telegramBtn/telegramBtn';
const HeroBannerCard = lazy(() => import('../heroBannerCard/heroBannerCard'));
import { useFirebase } from '@/app/context/Firebase';
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import HeroBannerCardSkeleton from '../heroBannerCard/heroBannerCardSkeleton';

const HeroSection = () => {
    const [loading, setLoading] = useState(true);
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const { firebaseDB: db } = useFirebase();
    const [heroBanner, setHeroBanner] = useState([]);

    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };


    useEffect(() => {
        const fetchHeroBanner = async () => {
            const q = query(
                collection(db, 'heroBanner'),
                where('status', '==', true),
                // orderBy('createdAt', 'desc'),
                limit(4)
            );
            const querySnapshot = await getDocs(q);
            const cards = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setHeroBanner(cards);
            setLoading(false);
        };

        fetchHeroBanner();
    }, [db]);

    return (
        !loading ?
            <section className={styles.section}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    keyboard={{
                        enabled: true,
                    }}
                    grabCursor={true}
                    effect={'fade'}
                    loop={true}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    modules={[Autoplay, Keyboard, EffectFade, Navigation, Pagination]}
                    onAutoplayTimeLeft={onAutoplayTimeLeft}
                    className={styles.swiper}
                >
                    {
                        heroBanner && heroBanner?.map((item) => (
                            <SwiperSlide key={item?.id} className={styles.swiperSlide}>
                                <Suspense fallback={<HeroBannerCardSkeleton />}>
                                    <HeroBannerCard item={item} loading={loading} />
                                </Suspense>
                            </SwiperSlide>
                        ))
                    }
                    <div className={styles.counterContainer} slot="container-end">
                        <svg viewBox="0 0 48 48" className={styles.svg} ref={progressCircle}>
                            <circle cx="24" cy="24" r="20"></circle>
                        </svg>
                        <span ref={progressContent}></span>
                    </div>
                </Swiper>
                <TelegramBtn />
            </section>
            :
            <HeroSectionSkeleton />
    );
}

export default HeroSection;
